import {useState, useEffect} from "react";

const useSubscription = (product, defaultSellingPlanId) => {
  const sellingPlans =
    product?.sellingPlanGroups.reduce(
      (acc, next) =>
        next?.selling_plans ? acc.concat(next?.selling_plans) : acc,
      [],
    ) ?? [];
  const hasSubscription = product?.sellingPlanGroups?.length > 0;
  const [isActive, setSubscriptionActive] = useState(
    product?.sellingPlanGroups?.length > 0,
  );
  const [sellingPlanId, setSellingPlanId] = useState(
    defaultSellingPlanId ?? (sellingPlans ? sellingPlans[0]?.id : null),
  );

  const getSellingPlan = id => {
    let sellingPlan = null;

    product.sellingPlanGroups?.find(({selling_plans}) => {
      const found = selling_plans.find(plan => plan.id === id);

      if (found) {
        sellingPlan = found;
      }
    });

    return sellingPlan;
  };

  useEffect(() => {
    if (!hasSubscription) {
      setSellingPlanId(null);
    }
  }, [hasSubscription]);

  useEffect(() => {
    if (!isActive) {
      setSellingPlanId(null);
    }
    if (isActive && !sellingPlanId) {
      setSellingPlanId(product?.sellingPlanGroups[0]?.selling_plans[0]?.id);
    }
  }, [isActive, product, sellingPlanId]);

  return {
    sellingPlans,
    hasSubscription,
    isActive,
    sellingPlanId,
    setSubscriptionActive,
    setSellingPlanId,
    getSellingPlan,
  };
};

export default useSubscription;
